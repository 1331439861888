<template>
    <div class="content">
        <div class="header">
            <div class="title">{{ $t('meeting_session.meeting_evaluation')}}</div>
            <slot name="header_menu_right"></slot>
        </div>
        <div class="body" v-if="loaded">
            <div class="container-form">
                <div class="sub-title-question">{{ $t('meeting_session.rating')}}</div>
                <div class="answer-rating">
                    <template v-for="n in 5">
                        <div class="option-rating" @click="setMeetingRating(n)" :class="{active: n == meeting_rating}">{{ n }}</div>
                    </template>
                </div>
                <div class="sub-title-question">{{ $t('meeting_session.improvement_question')}}</div>
                <div class="answer-textarea">
                    <textarea :placeholder="$t('meeting_session.answer_question')" rows="25" v-model="improvement_answer" v-debounce:800="saveReview"></textarea>
                </div>
                <!-- <div class="sub-title-question">{{ $t('meeting_session.motivation_question')}}</div>
                <div class="answer-textarea">
                    <textarea :placeholder="$t('meeting_session.answer_question')" rows="4" v-model="motivation_answer" v-debounce:800="saveReview"></textarea>
                </div>
                <div class="sub-title-question">{{ $t('meeting_session.collaboration_question')}}</div>
                <div class="answer-textarea">
                    <textarea :placeholder="$t('meeting_session.answer_question')" rows="4" v-model="colaboration_answer" v-debounce:800="saveReview"></textarea>
                </div> -->
                <slot name="form_submit"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import IconTask from '@/components/Icons/Task'
import IconCheck from '@/components/Icons/Check'
import IconFlag from '@/components/Icons/Flag'

export default {
    components: {
        IconTask,
        IconCheck,
        IconFlag
    },
    computed: {

    },
    props: {
        optionsUsers: Array,
        data: Object|Boolean
    },
    data() {
        return {
            loaded: false,
            motivation_answer: '',
            colaboration_answer: '',
            improvement_answer: '',
            meeting_rating: ''
        }
    },
    async mounted() {
        await this.getReview();
    },
    methods: {
        async getReview() {
            await axios.get(`/public/meeting-session/${this.$route.params.uuid}/review-show`, { params: { token_link: this.$route.query.token_user ? this.$route.query.token_user : ''} } )
            .then(({data}) => {
                this.meeting_rating = data.data ? data.data.rating : '';

                if(data.data && data.data.review) {
                    JSON.parse(data.data.review).forEach((item) => {
                        // if(item.type == 'motivation_question') {
                        //     this.motivation_answer = item.answer;
                        // }
                        // if(item.type == 'collaboration_question') {
                        //     this.colaboration_answer = item.answer;
                        // }
                        if(item.type == 'improvement_question') {
                            this.improvement_answer = item.answer;
                        }
                    })
                }
            })
            .finally(() => {
                this.loaded = true;
            })
        },
        setMeetingRating(value) {
            this.meeting_rating = value;
            this.saveReview();
        },
        saveReview(){
            var fromData = {
                review: JSON.stringify([
                    // {
                    //     type: 'motivation_question',
                    //     answer: this.motivation_answer
                    // },
                    // {
                    //     type: 'collaboration_question',
                    //     answer: this.colaboration_answer
                    // },
                    {
                        type: 'improvement_question',
                        answer: this.improvement_answer
                    },
                    {
                        type: 'rating',
                        answer: this.meeting_rating
                    },
                ]),
                rating: this.meeting_rating,
            }

            if(this.$route.query.token_link) {
                fromData.token_link = this.$route.query.token_link;
            }
            
            axios.post(`/public/meeting-session/${this.$route.params.uuid}/meeting-review`, fromData)
            .then(({data}) => {

            })
        }
    }
}
</script>